import React from 'react'
import RegenerateDropdown from '../../Dropdowns/RegenerateDropdown';
import CopyButton from '../../Button/CopyButton';

const ActionSingleMessage = ({
  regenFunc,
  chatId,
  handlePrevious,
  handleNext,
  disablePrevious,
  disableNext,
  currentIndex,
  totalAnswers,
  showLoadingAnswer,
  content,
  files,
  regenerateIndex,
  setRegenerateIndex,
  index,
}) => (
  <div className="flex justify-between items-center w-full gap-2 sm:gap-0 my-2 mt-2">
    <div className="cursor-pointer h-[32px]">
      <CopyButton text={content} />
    </div>

    <div className="flex justify-end gap-1.5 items-center w-full h-full sm:w-auto text-xs">
      {totalAnswers > 1 && (
        <div className="flex items-center gap-1.5 sm:gap-2">
          <button
            className={`dark:text-white p-0.5 sm:p-0 ${
              disablePrevious
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer hover:text-gray-600 dark:hover:text-gray-300"
            }`}
            onClick={handlePrevious}
            disabled={disablePrevious}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>

          <span className="dark:text-white font-medium text-xs">
            {currentIndex + 1}/{totalAnswers}
          </span>

          <button
            className={`dark:text-white p-0.5 sm:p-0 ${
              disableNext
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer hover:text-gray-600 dark:hover:text-gray-300"
            }`}
            onClick={handleNext}
            disabled={disableNext}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      )}
      {totalAnswers > 0 && (
          <RegenerateDropdown
            chatId={chatId}
            onRegenerate={regenFunc}
            showLoadingAnswer={showLoadingAnswer}
            files={files}
            regenerateIndex={regenerateIndex}
            setRegenerateIndex={setRegenerateIndex}
            index={index}
          />
      )}
    </div>
  </div>
);

export default ActionSingleMessage;