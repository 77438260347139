import React from "react";
import { CrossSvg, BlackCross } from "../../assets";
import { useTheme } from "../../context/ThemeProvider";

const FileUploadItem = ({ file, onRemove, progress = 0 }) => {
  const { theme } = useTheme();

  return (
    <div className="relative">
      <div
        className={`
          flex items-center gap-2 px-3 border-[0.5px] py-1 rounded-full min-w-fit text-xs 
          ${theme === "dark"
            ? "bg-[#454E5A] border-[#6D808D] text-[#CCD6F6]"
            : "bg-[#F0F2F4] border-[#66666633] text-[#0D3148]"
          }
          relative overflow-hidden
        `}
      >
        {/* Filename and close button */}
        <span className="text-xs sm:text-sm max-w-[150px] truncate font-semibold">
          {file.name}
        </span>
        <button
          onClick={() => onRemove(file)}
          className="text-gray-400 hover:text-gray-300 transition-colors flex-shrink-0"
          aria-label={`Remove ${file.name}`}
        >
          {theme === "dark" ? (
            <CrossSvg className="h-4" />
          ) : (
            <BlackCross className="h-4" />
          )}
        </button>

        {/* Progress line at bottom - with fixed positioning */}
        {(file?.progress || progress) > 0 && (file?.progress || progress) < 100 && (
          <div
            className="absolute bottom-0 left-0 right-0 h-[1px] bg-[#FAAD47] origin-left transition-transform duration-300 ease-in-out"
            style={{
              transform: `scaleX(${(file?.progress || progress || 0) / 100})`,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FileUploadItem;