import React, { useEffect, useState } from "react";
import { useTheme } from "../context/ThemeProvider";
import { useNavigate } from "react-router-dom";
import bgLight from "../assets/images/bg-light.jpg";
import Sidebar from "../components/Sidebar/Sidebar";
import MainContent from "../components/MainContent/MainContent";
import UserAuthStore from "../store/userStore";
import Loader from "../components/Loader/Loader";

const AppLayout = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const user = UserAuthStore((state) => state.user);
  const [isHydrated, setIsHydrated] = useState(false);

  // Wait for Zustand state to hydrate
  useEffect(() => {
    const checkAuth = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Ensure store hydration
      setIsHydrated(true);
    };
    checkAuth();
  }, []);

  useEffect(() => {
    if (isHydrated && !user) {
      navigate("/login", { replace: true });
    }
  }, [user, isHydrated, navigate]);

  // Block rendering until Zustand is hydrated
  if (!isHydrated || (isHydrated && !user)) {
    return (
      <div className="h-screen flex items-center justify-center dark:bg-[#22222D] bg-white">
        <Loader />
      </div>
    );
  }

  return (
    <main
      className={`flex w-screen h-[100dvh] ${
        theme === "dark" ? "dark:bg-bgColor bg-bgColor text-gray-100" : ""
      }`}
      style={{
        backgroundImage: theme === "light" ? `url(${bgLight})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Sidebar />
      <MainContent />
    </main>
  );
};

export default AppLayout;
