import React, { useEffect, useRef } from "react";
import UIStore from "../../../store/uiStore";
import { getModeAuth } from "../../../utils/firestoreFunctions/getModeAuth";
import { onAuthStateChanged, signOut } from "firebase/auth";
import EnvStore from "../../../store/secretStore";
import { fetchAllOrganizationDomains } from "../../../utils/firestoreFunctions/fetchOrgDomains";
import UserAuthStore from "../../../store/userStore";
import { useNavigate } from "react-router-dom";
import { getChat } from "../../../utils/firestoreFunctions/getChat";
import { addCopyButtons } from "../../../utils/addCopyButtons";
import FileUploadStore from "../../../store/fileUploadStore";
import { useTheme } from "../../../context/ThemeProvider";
import LogoPng from "../../../assets/images/Photon_logo_dark.png";
import LogoPngLight from "../../../assets/images/Photon_logo_light.png";
import "../../../assets/css/header.css";
import { logout } from "../../../api/logout";
import UserDropdown from "../../Dropdowns/UserDropdown";

const Header = () => {
  const navigate = useNavigate();
  const messageRef = useRef([]);
  const scrollRef = useRef(null);
  const { showSidebar, setLoadingAuth } = UIStore();
  const { setUser, setOrgId } = UserAuthStore();
  const { setInputFileName, setFileSize, setFileUrl, setShowUpload } =
    FileUploadStore();
  const { theme, setTheme } = useTheme();

  const RemoveUpload = () => {
    setInputFileName([]);
    setFileSize([]);
    setFileUrl([]);
    setShowUpload(false);
  };

  useEffect(() => {
    setLoadingAuth(true);
    onAuthStateChanged(EnvStore.getState().auth, async (user) => {
      if (user) {
        setLoadingAuth(false);
        const domainMap = await fetchAllOrganizationDomains();
        const domains = Object.keys(domainMap);
        const userDomain = user.email.split("@")[1];
        if (!domains.includes(userDomain)) {
          setUser(null);
          try {
            await signOut();
          } catch (error) {
            console.log("user already signed out");
          } finally {
            navigate("/");
          }
        } else {
          setOrgId(domainMap[userDomain]);
          setUser(user);
          getChat(messageRef, scrollRef, addCopyButtons, true, RemoveUpload);
          getModeAuth();
        }
      } else {
        setLoadingAuth(true);
        logout(setTheme);
      }
    });
  }, [setLoadingAuth]);

  return (
    <header
      className={`sticky top-0 w-full z-50 flex-shrink-0 flex justify-between px-6 py-2 ${
        theme === "dark"
          ? "text-white border-b border-gray-700 bg-[#22222D]"
          : "text-black border-b border-gray-300"
      }`}
    >
      {/* Logo Section (Hidden below 992px) */}
      <div
        className={`items-center ${
          showSidebar
            ? ""
            : "ml-12 border-l border-gray-300 dark:border-gray-700 pl-4"
        } hidden lg:flex`}
      >
        {theme === "dark" ? (
          <img src={LogoPng} alt="Logo" className="w-[135px] h-[30px]" />
        ) : (
          <img src={LogoPngLight} alt="Logo" className="w-[135px] h-[30px]" />
        )}
      </div>

      {/* Mobile layout: Logo, and User Dropdown */}
      <div className="flex w-full lg:hidden justify-end items-center">
          <UserDropdown theme={theme} />
      </div>

      <div className="hidden lg:flex justify-between space-x-4">
        <div className="border-l border-gray-300 dark:border-gray-700"></div>
        {/* User Dropdown (On right side on all screen sizes) */}
        <div className="hidden lg:flex justify-end">
          <UserDropdown theme={theme} />
        </div>
      </div>
    </header>
  );
};

export default Header;
