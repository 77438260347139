import React, { useEffect, useRef, useState } from "react";
import UIStore from "../../store/uiStore";
import { useTheme } from "../../context/ThemeProvider";
import "../../assets/css/styles.css";
import { SideBarHeader, SidebarToggler1 } from "./SidebarHeader";
import { NewChatButton } from "./NewChatButton";
import { SidebarChatList } from "./SidebarChatList";
import ChatStore from "../../store/chatStore";

const Sidebar = () => {
  const sideRef = useRef(null);
  const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const { showSidebar, setShowSidebar } = UIStore();
  const { setEditChatId } = ChatStore();

  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 1024);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sideRef.current &&
        !sideRef.current.contains(event.target) &&
        window.innerWidth < 1025
      ) {
        toggleSidebar(false);
        setEditChatId(null);
      }
    };

    if (showSidebar) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSidebar]);

  return (
    <div className="p-3 xxs:p-0 xs:p-0 xm:py-2">
      {showSidebar && window.innerWidth < 1024 && (
        <div
          className="fixed inset-0 bg-[#22222D] bg-opacity-[40%] z-50"
          onClick={toggleSidebar}
        />
      )}
      <SidebarToggler1
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
        theme={theme}
      />

      {showSidebar && (
        <div
          ref={sideRef}
          className={`xm:mx-1 sidebar transition-opacity duration-250 z-[1000] h-full rounded-[15px] w-[17.56vw] min-w-[240px] relative flex flex-col flex-shrink-0 px-2 ${
            theme === "dark"
              ? "bg-[#30303D] border-dotted border-black"
              : "bg-white border-gray-300"
          }`}
        >
          {/* Header */}
          <SideBarHeader
            theme={theme}
            toggleSidebar={toggleSidebar}
            toggleTheme={toggleTheme}
            isSettingsDropdownOpen={isSettingsDropdownOpen}
            setIsSettingsDropdownOpen={setIsSettingsDropdownOpen}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <hr
            className={`mt-2 ${
              theme === "dark" ? "border-gray-700" : "border-gray-300"
            }`}
          />

          {/* New Chat Button */}
          <NewChatButton theme={theme} />
          <hr
            className={`mt-3 ${
              theme === "dark" ? "border-gray-700" : "border-gray-300"
            }`}
          />
          {/* Chat List */}
          <SidebarChatList theme={theme} />

          {/* gradient for the overflow */}
          <div
            className={`absolute bottom-0 right-0 w-full h-14 rounded-[15px]
            ${theme === "dark" ? "sidebar-gradient" : "sidebar-gradient-light"}
              `}
          ></div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
