import { create } from "zustand";

const ChatStore = create((set) => ({
  sideChat: [],
  activeChat: "",
  editChatId: null,
  editTitle: "",
  addChatDisabled: false,
  messages: [],
  messageRef: [],
  setSideChat: (chats) => {
    set({ sideChat: chats });
  },
  setActiveChat: (chatId) => set({ activeChat: chatId }),
  setEditChatId: (id) => set({ editChatId: id }),
  setEditTitle: (title) => set({ editTitle: title }),
  setAddChatDisabled: (value) => set({ addChatDisabled: value }),
  setMessages: (value) => set({ messages: value }),

  updateChatTitle: (uid, title) => {
    set((state) => ({
      sideChat: state.sideChat.map((chat) => {
        if (chat.uid === uid && chat.title !== title) {
          return { ...chat, title: title };
        }
        return chat;
      }),
    }));
  },
  deleteChatTitle: (uid) => {
    set((state) => ({
      sideChat: state.sideChat.filter((chat) => chat.uid !== uid),
    }));
  },

  isLatestMessageLoading: false,
  setIsLatestMessageLoading: (value) => set({ isLatestMessageLoading: value }),

  generateChatId: null,
  setGenerateChatId: (value) => set({ generateChatId: value }),

  tempChatId: null,
  setTempChatId: (value) => set({ tempChatId: value }),
}));

export default ChatStore;
