import { useTheme } from "../../context/ThemeProvider";
import { WebIconDark, WebIconLight } from "../../assets";
import CustomTooltip from "../ToolTip/ToolTip";


export default function GlobalSearch({ expanded, setExpanded, disabled }) {
  const { theme } = useTheme();

  const handleToggleExpand = () => {
    if (!disabled) {
      setExpanded(!expanded);
    }
  };

  return (
    <div className="relative">
      {disabled ? (
        <CustomTooltip text="Search disabled for Attachments">
          <button
            className="flex items-center px-2 h-8 rounded-full transition-all duration-1000 ease-out border opacity-50 cursor-not-allowed"
            disabled
          >
            {theme === "dark" ? (
              <WebIconDark className="h-4 w-4 fill-white" />
            ) : (
              <WebIconLight className="h-4 w-4 text-[#6D808D]" />
            )}
            <span className="overflow-hidden transition-transform ease-in-out text-[13px] opacity-0 w-0">
              Search
            </span>
          </button>
        </CustomTooltip>
      ) : (
        <button
          className={`flex items-center px-2 h-8 rounded-full transition-all duration-1000 ease-out border ${
            expanded ? "w-[85px] gap-2 ease-out duration-500" : ""
          } ${
            theme === "dark"
              ? expanded
                ? "bg-[#37E8C51A] text-[#37E8C5] border-[#37E8C5]" 
                : "bg-[#333341] border-[#6D808D] text-white" 
              : expanded
              ? "bg-[#FFE9E6] text-[#FF5B29] border-[#6D808D4D]"
              : "bg-[#FFE9E6] text-[#FF5B29] border-[#E5E5E5]" 
          }`}
          onClick={handleToggleExpand}
        >
          {theme === "dark" ? (
            <WebIconDark
              className={`h-4 w-4 ${
                expanded ? "text-[#37E8C5] fill-[#37E8C5]" : "fill-white"
              }`}
            />
          ) : (
            <WebIconLight
              className={`h-4 w-4 ${
                expanded ? "text-[#FF5B29] fill-[#FF5B29]" : "text-[#6D808D]"
              }`}
            />
          )}
          <span
            className={`overflow-hidden transition-transform ease-in-out text-[13px] ${
              expanded ? "opacity-100 w-auto" : "opacity-0 w-0"
            }`}
          >
            Search
          </span>
        </button>
      )}
    </div>
  );
}
