import { useRef, useState } from "react";

const CustomTooltip = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);
  const containerRef = useRef(null);

  return (
    <div ref={containerRef} className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="cursor-pointer"
      >
        {children}
      </div>

      {isVisible && (
        <div
          ref={tooltipRef}
          className="absolute z-[100] bottom-full left-0 w-max
              transform -translate-y-2
              bg-[#000000] text-white 
              px-3 py-2
              rounded-md text-xs
              whitespace-nowrap"
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
