import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../context/ThemeProvider";
import { Attachment, BlackAttachment, BlackPlus, Plus } from "../../assets";
import CustomTooltip from "../ToolTip/ToolTip";

const AttachDropdown = ({ fileInputRef, expanded, filesLength }) => {
  const { theme } = useTheme();
  const attachDropdownRef = useRef(null);
  const [isAttachOpen, setIsAttachOpen] = useState(false);

  const handleFileInputClick = () => {
    setIsAttachOpen(false);
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const toggleAttachDropdown = () => {
    setIsAttachOpen(prev => !prev);
  };

  const attachMenuItems = [
    {
      icon: theme === "dark" ? Attachment : BlackAttachment,
      label: "Upload File",
      onClick: handleFileInputClick,
    },
    // {
    //   label: "Choose from Drive",
    //   onClick: () => {
    //     setIsAttachOpen(false);
    //   },
    // },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        attachDropdownRef.current &&
        !attachDropdownRef.current.contains(event.target) &&
        event.target !== fileInputRef.current // Ensure file input click doesn't close dropdown
      ) {
        setIsAttachOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={attachDropdownRef}>
    {expanded && filesLength === 0 ? (
      <CustomTooltip text="Attachment disabled for search mode.">
        <button
          onClick={toggleAttachDropdown}
          disabled
          className="flex items-center gap-2 px-3 sm:px-4 h-8 rounded-full transition-colors border opacity-50 cursor-not-allowed"
        >
          {theme === "dark" ? <Plus className="h-4" /> : <BlackPlus className="h-4" />}
          <span className="hidden sm:inline text-[13px] text-[#0C3148] dark:text-white opacity-100">
            Attach
          </span>
        </button>
      </CustomTooltip>
    ) : (
      <button
        onClick={toggleAttachDropdown}
        className={`flex items-center gap-2 px-3 sm:px-4 h-8 rounded-full transition-colors border ${
          theme === "dark"
            ? "bg-[#333341] border-[#6D808D]"
            : "bg-[#FEEAE6] border-[#6D808D4D]"
        }`}
      >
        {theme === "dark" ? <Plus className="h-4" /> : <BlackPlus className="h-4" />}
        <span className="hidden sm:inline text-[13px] text-[#0C3148] dark:text-white opacity-100">
          Attach
        </span>
      </button>
    )}

    {isAttachOpen && (!expanded || filesLength > 0) && (
      <div
        className={`absolute bottom-full mb-2 left-0 w-48 border rounded-md shadow-lg z-50 ${
          theme === "dark"
            ? "bg-[#424453] border-[#66666666] opacity-100"
            : "bg-white border-[#E5E5E5]"
        }`}
      >
        <div className="m-1">
          {attachMenuItems.map((item) => (
            <button
              key={item.label}
              onClick={item.onClick}
              className={`w-full flex items-center px-4 py-2 text-[13px] ${
                theme === "dark"
                  ? "text-gray-300 hover:bg-[#3F3F4A]"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              <item.icon className="w-4 h-4 mr-3" color={theme === "dark" ? "#37E8C5" : "#FF5B29"} />
              {item.label}
            </button>
          ))}
        </div>
      </div>
    )}
  </div>
  );
};

export default AttachDropdown;
