import { create } from "zustand";

const ScrollToBottomStore = create((set) => ({
  showScrollButton: false,
  setShowScrollButton: (value) => {
    set({ showScrollButton: value });
  },
}));

export default ScrollToBottomStore;
