import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import PromptStore from "../store/promptStore";
import { getCacheAndFileData } from "../utils/firestoreFunctions/getCacheandFileData";
import { getRecachingValue } from "../utils/firestoreFunctions/getRecaching";
import { v4 as uuidv4 } from "uuid";
import EnvStore from "../store/secretStore";
import { FileStore } from "../store/fileUploadStore";
import { summariseTitleApi } from "./SummariseTitle";
import useGenerateApiSignal from "../store/useGenerateApiSignal";

const scrollToBottom = (smooth = true) => {
  const scrollRef = document.querySelector("#chat-messages");
  if (scrollRef) {
    if (smooth)
      scrollRef.scrollTo({ top: scrollRef.scrollHeight, behavior: "smooth" });
    else
      scrollRef.scrollTo({
        top: scrollRef.scrollHeight,
        behavior: "instant",
      });
  }
};

export const handleSend = async ({
  chat_id,
  expanded,
  selectedStyle,
  setMessages,
  messages,
  setRegenerateIndex,
  setIsLatestMessageLoading,
  setAddChatDisabled,
  updateChatTitle,
  user,
  orgId,
  files,
  modeRef,
  apiUrl,
  defaultModel,
  setGenerateStatus,
}) => {
  const { prompt, setPrompt } = PromptStore.getState();
  const filesToBeUploaded = FileStore.getState().files;
  const promptId = uuidv4();
  setPrompt(""); // clear the prompt from the input box.
  setIsLatestMessageLoading(true);
  setGenerateStatus(true);
  setRegenerateIndex(messages.length);
  setMessages((prev) => [
    ...prev,
    {
      role: "user",
      content: prompt,
      answers: [
        {
          content: "",
          timestamp: serverTimestamp(),
          source: "None",
          active: true,
          role: "model",
        },
      ],
      currentIndex: 0,
      source: filesToBeUploaded.filter(file => file?.downloadURL)?.map((file) => file.downloadURL) || [],
      id: promptId,
      expanded,
      selectedStyle,
    },
  ]);
  const chatDocRef = doc(
    EnvStore.getState().db,
    "organisation",
    orgId,
    "users",
    user?.uid,
    "chats",
    chat_id
  );
  setAddChatDisabled(false);
  try {
    const reader = await generateApi({
      apiUrl,
      modeRef,
      prompt,
      chat_id,
      files,
      orgId,
      user,
      defaultModel,
      expanded,
      selectedStyle,
      promptId,
    });
    const decoder = new TextDecoder("utf-8");
    let answerCont = "";
    let first = true;
    for (let done = false; !done;) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;
      if (value) {
        const chunk = decoder.decode(value, { stream: true });
        answerCont += chunk;

        // to check the conditions for messages
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessage = updatedMessages[updatedMessages.length - 1];

          if (lastMessage && lastMessage.answers) {
            if (!lastMessage.answers[lastMessage.currentIndex ?? 0].content.includes(chunk)) {
              lastMessage.answers[lastMessage.currentIndex ?? 0].content = answerCont;
            }
          }
          return updatedMessages;
        });
        if (first) {
          first = false;
          setTimeout(() => scrollToBottom(), 100);
        }
      }
    }
    setRegenerateIndex(null);
    setGenerateStatus(false);
    setIsLatestMessageLoading(false);
  } catch (error) {
    if (error.name === "AbortError") {
      setRegenerateIndex(null);
      setGenerateStatus(false);
      setIsLatestMessageLoading(false);
    } else {
      console.error("Error fetching messages:", error);
    }
  }
  await updateDoc(chatDocRef, { updated_at: serverTimestamp() });

  if (messages.length === 0) {
    const generatedTitle = await summariseTitleApi({ prompt, promptId, orgId, chat_id, user });
    updateChatTitle(chat_id, generatedTitle["summary_title"]);
  }
};


export async function generateApi({
  apiUrl,
  modeRef,
  prompt,
  chat_id,
  orgId,
  user,
  defaultModel,
  expanded,
  selectedStyle,
  promptId,
}) {
  const dta = await getCacheAndFileData(chat_id);
  const recachingValue = await getRecachingValue(chat_id);

  const cacheId = dta?.cache_id ?? null;
  const fileData = dta?.file_data ?? "";
  const filesToBeUploaded = FileStore.getState().files;
  const body = JSON.stringify({
    type: modeRef,
    prompt: prompt,
    chat_uid: chat_id,
    file_url: filesToBeUploaded.filter(file => file?.downloadURL)?.map((file) => file.downloadURL) || [],
    org_id: orgId,
    uid: user?.uid,
    regenerate: false,
    style: selectedStyle,
    model_id: defaultModel,
    recaching: recachingValue || false,
    google_search: expanded,
    cache_id: cacheId,
    file_data: fileData,
    prompt_id: promptId,
    new_prompt: "",
  });
  //code for api abort/pause
  useGenerateApiSignal.getState().signal?.abort();
  const newAbortSignal = new AbortController();

  const response = await fetch(apiUrl + "/generate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
    signal: newAbortSignal.signal,
  });

  useGenerateApiSignal.getState().setSignal(newAbortSignal);
  if (!response.body) {
    throw new Error("Readable stream not supported in response.");
  }

  return response.body.getReader();
}
