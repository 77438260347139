import React, { useState, useEffect } from "react";
import MessageAlert from "../components/Authentication/MessageAlert";
import OAuthButton from "../components/Authentication/OAuthButton";
import Footer from "../components/Authentication/Footer";
import { Logo, LogoLight } from "../assets";
import { signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { isUserInFirestore } from "../utils/firebaseUtils";
import { fetchAllOrganizationDomains } from "../utils/firestoreFunctions/fetchOrgDomains";
import { updateUserDocumentId } from "../utils/firestoreFunctions/updateUserId";
import UserAuthStore from "../store/userStore";
import EnvStore from "../store/secretStore";
import { useTheme } from "../context/ThemeProvider";
import Loader from "../components/Loader/Loader";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { setUser, setOrgId } = UserAuthStore.getState();
  const { theme } = useTheme();

  const handleLogin = async () => {
    setLoading(true);
    try {
      const domainMap = await fetchAllOrganizationDomains();
      const domains = Object.keys(domainMap);
      const provider = EnvStore.getState().googleProvider;
      const auth = EnvStore.getState().auth;

      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDomain = user.email.split("@")[1];

      if (!domains.includes(userDomain)) {
        setLoading(false);
        toast.warning("Your Domain is not authorized");
        await signOut(auth);
      } else {
        setOrgId(domainMap[userDomain]);
        const isUser = await isUserInFirestore(
          EnvStore.getState().db,
          domainMap[userDomain],
          user.uid
        );

        if (!isUser) {
          const done = await updateUserDocumentId(
            user.email,
            user.uid,
            domainMap[userDomain],
            user
          );

          if (done === true) {
            navigate("/chat");
          } else {
            setLoading(false);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error signing in please try again");
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      EnvStore.getState().auth,
      async (user) => {
        if (user) {
          const domainMap = await fetchAllOrganizationDomains();
          const userDomain = user.email.split("@")[1];
          const domains = Object.keys(domainMap);

          if (!domains.includes(userDomain)) {
            try {
              await signOut();
            } catch (error) {
              console.log("User already signed out");
            }
            setLoading(false);
          } else {
            setOrgId(domainMap[userDomain]);
            setUser(user);
            // to check if it is needed
            const isUser = await isUserInFirestore(
              EnvStore.getState().db,
              domainMap[userDomain],
              user.uid
            );
            if (isUser) {
              navigate("/chat");
            } else {
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, [navigate]);

  return (
    <>
      {loading ? (
        <div
          className={`h-screen flex items-center justify-center dark:bg-[#22222D] bg-white ${
            theme === "light" ? "bg-[LightBg] bg-cover bg-center" : ""
          }`}
        >
          <Loader />
        </div>
      ) : (
        <div
          className={`h-screen relative dark:bg-[#22222D] dark:text-white bg-[#F9F7F5] text-black`}
        >
          <MessageAlert />
          <div className="flex justify-center items-center h-full">
            <div className="absolute top-32">
              {theme === "dark" ? <Logo /> : <LogoLight />}
            </div>
            <OAuthButton theme={theme} onSignIn={handleLogin} />
            <Footer theme={theme} />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
