import React, { useState, useEffect, useRef } from "react";
import { getModelList } from "../../api/getModels";
import { FaArrowsRotate, FaCheck, FaChevronUp } from "react-icons/fa6";
import { WebIconDark, WebIconLight } from "../../assets";
import { useTheme } from "../../context/ThemeProvider";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import { getDefaultModel } from "../../api/getDefaultModel";

const RegenerateDropdown = ({
  chatId,
  onRegenerate,
  showLoadingAnswer,
  files,
  setRegenerateIndex,
  index,
}) => {
  const { theme } = useTheme();
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [hoveredModel, setHoveredModel] = useState(null);
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isWebSearchChecked, setIsWebSearchChecked] = useState(false);
  const [isDropdownAbove, setIsDropdownAbove] = useState(false); // to check the dropdown's position
  const [defaultModelId, setDefaultModelId] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      setIsLoading(true);
      try {
        const response = await getModelList();
        if (response?.models) {
          const modelsArray = Object.values(response.models).map(
            ({ id, name, active }) => ({
              id,
              name,
              active: active === "True" || active === true || active === "true",
            })
          );

          const activeModels = modelsArray.filter((model) => model.active);
          setModels(activeModels);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
        setModels([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchModels();
  }, []);

  const handleModelSelect = async (model) => {
    setRegenerateIndex(index);
    if (model.id === "gemini-2.0-flash-001" && isWebSearchChecked) {
      toast.error("Google Search is not available for this model");
      setIsOpen(false);
      return;
    } else {
      setSelectedModel(model.id);
      setIsOpen(false);
      await onRegenerate(chatId, model.id, isWebSearchChecked);
    }
  };

  const handleWebSearchChange = async (e) => {
    const isChecked = e.target.checked;
    setIsWebSearchChecked(isChecked);
    setRegenerateIndex(index);
    if (isChecked) {
      try {
        const response = await getDefaultModel();
        const modelId = response?.id || defaultModelId;
        setDefaultModelId(modelId);
        await onRegenerate(chatId, modelId, true);
      } catch (error) {
        setIsOpen(false);
        console.error("Error fetching default model:", error);
        toast.error("Failed to fetch default model");
      }
    }
  };

  const handleOnChange = (e) => {
    e.stopPropagation();
    handleWebSearchChange(e);
  };

  const handleOnClickForFileCheck = () => {
    //to check if files are present
    if (files.length === 0) {
      handleWebSearchChange({
        target: { checked: !isWebSearchChecked },
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const shouldOpenAbove = buttonRect.top > 320;

      setIsDropdownAbove(shouldOpenAbove); // Set state based on position

      if (shouldOpenAbove) {
        dropdownRef.current.style.top = `${
          buttonRect.bottom - dropdownRef.current.offsetHeight - 28 - 2
        }px`;
      } else {
        dropdownRef.current.style.top = `${buttonRect.bottom + 2}px`;
      }
      dropdownRef.current.style.left = `${buttonRect.right - 250}px`;
    }
  }, [isOpen, buttonRef]);

  useEffect(() => {
    if (files.length > 0) {
      setIsWebSearchChecked(false);
    }
  }, [files]);

  return (
    <div className="relative" style={{ zIndex: 10 }}>
      <button
        ref={buttonRef}
        onClick={() => {
          if (!showLoadingAnswer) {
            setIsOpen((prev) => !prev);
          }
        }}
        className={`flex items-center gap-2 text-sm rounded-lg p-2
          ${
            isOpen
              ? "dark:bg-[#22222D] bg-[#66666633] "
              : "dark:bg-[#444858] bg-[#EEEEEE]"
          }
          ${
            showLoadingAnswer
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          }
          transition-transform duration-700`}
      >
        <FaArrowsRotate
          className={`w-3 h-3 transition-transform duration-700 
      ${
        isOpen
          ? "dark:fill-[#9FABCA] fill-[#6e808d]"
          : "dark:fill-[#37E8C5] fill-[#FF5B29]"
      }
      ${isOpen ? "rotate-180" : "rotate-0"}`}
        />
        <span
          className={`text-xs transition-colors duration-300
      ${
        isOpen
          ? "dark:text-[#9FABCA] text-[#85969f]"
          : "dark:text-[#37E8C5] text-[#FF5B29]"
      }`}
        >
          Regenerate
        </span>
        {/* Up Arrow when dropdown is open */}
        {isOpen && (
          <FaChevronUp
            className={`w-3 h-3 transition-colors duration-300
            ${
              isOpen
                ? "dark:fill-white fill-[#273139]"
                : "dark:fill-emerald-400 fill-[#FF5B29]"
            } 
            ${isDropdownAbove ? "" : "rotate-180"}`}
          />
        )}
      </button>

      {isOpen &&
        createPortal(
          <div
            ref={dropdownRef}
            style={{
              position: "absolute",
              width: "250px",
              zIndex: 50,
            }}
            className="rounded-lg dark:bg-[#424453] bg-white border dark:border-[#66666666] border-[#6D808D4D] transition-opacity duration-700"
          >
            <div className="px-4 py-3 text-sm dark:text-[#9FABCA] text-gray-400">
              Switch model
            </div>

            <div className="min-h-52 max-h-52 overflow-y-auto  border-b border-[#6D808D4D]">
              {isLoading ? (
                <div className="px-4 py-2 text-gray-400 text-sm text-center">
                  Loading models...
                </div>
              ) : models.length > 0 ? (
                models.map((model) => (
                  <div
                    key={model.id}
                    onClick={() => handleModelSelect(model)}
                    onMouseEnter={() => setHoveredModel(model.id)}
                    onMouseLeave={() => setHoveredModel(null)}
                    className="px-4 py-2 m-1 rounded-lg flex items-center justify-between dark:text-white text-gray-600 hover:bg-gray-500/20 cursor-pointer group"
                  >
                    <span className="text-sm">{model.name}</span>
                    {selectedModel === model.id ? (
                      hoveredModel === model.id ? (
                        <button
                          className="dark:text-emerald-400 text-[#FF5B29] text-xs px-1 py-1 rounded flex items-center gap-1 hover:bg-emerald-400/10"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleModelSelect(model);
                          }}
                        >
                          Try Again
                          <FaArrowsRotate className="w-3 h-3" />
                        </button>
                      ) : (
                        <FaCheck className="w-4 h-4 dark:text-emerald-400 text-[#FF5B29]" />
                      )
                    ) : null}
                  </div>
                ))
              ) : (
                <div className="px-4 py-2 text-gray-400 text-sm text-center">
                  No models available
                </div>
              )}
            </div>

            <div
              className={`m-1 rounded-lg px-4 py-2 hover:bg-gray-500/20 flex items-center justify-between gap-2 dark:text-white ${
                files.length > 0
                  ? "cursor-not-allowed text-gray-400"
                  : "cursor-pointer"
              }`}
              onClick={handleOnClickForFileCheck}
            >
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  id="webSearch"
                  checked={isWebSearchChecked}
                  onChange={handleOnChange}
                  disabled={files.length > 0}
                  className={`dark:accent-emerald-400 accent-[#FF5B29] w-4 h-4 ${
                    files.length > 0
                      ? "cursor-not-allowed text-gray-400"
                      : "cursor-pointer"
                  }`}
                />
                <label
                  htmlFor="webSearch"
                  className={`text-sm ${
                    files.length > 0
                      ? "cursor-not-allowed text-gray-400"
                      : "cursor-pointer"
                  }`}
                >
                  Search the web
                </label>
              </div>
              {theme === "dark" ? (
                <WebIconDark
                  className={`h-4 w-4 ${
                    isWebSearchChecked ? "fill-[#37E8C5]" : "fill-white"
                  } ${
                    files.length > 0
                      ? "opacity-50 cursor-not-allowed fill-gray-500"
                      : "fill-[#37E8C5]"
                  }`}
                />
              ) : (
                <WebIconLight
                  className={`h-4 w-4 ${
                    isWebSearchChecked ? "fill-[#FF5B29]" : "fill-black"
                  } ${
                    files.length > 0
                      ? "opacity-50 cursor-not-allowed fill-gray-400"
                      : "fill-[#FF5B29]"
                  }`}
                />
              )}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default RegenerateDropdown;
