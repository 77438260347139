

export const useDragAndDrop = () => {

  const handleDrop = (e, fileInputRef, handleFileUpload, chat_id, setFiles, files, user, orgId) => {
    e.preventDefault();

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (fileInputRef.current) {
        const dt = new DataTransfer();
        Array.from(e.dataTransfer.files).forEach((file) => dt.items.add(file));
        fileInputRef.current.files = dt.files;

        const event = new Event("change", { bubbles: true });
        fileInputRef.current.dispatchEvent(event);

        handleFileUpload(
          e,
          { target: fileInputRef.current },
          chat_id,
          fileInputRef,
          setFiles,
          files,
          user,
          orgId
        );
      }
    }
  };

  return {
    handleDrop,
  };
};
