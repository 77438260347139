import Copy from "../assets/images/copy.svg";
import Magic from "../assets/images/magic.svg";

const copyCode = (block, copy_div) => {
    const code = block.textContent;
    navigator.clipboard
      .writeText(code)
      .then(() => {
        const img = copy_div.querySelector("img");
        if (img) {
          img.src = Magic;
        }
        setTimeout(() => {
          if (img) {
            img.src = Copy;
          }
        }, 500);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

export const addCopyButtons = () => {
    const codeBlocks = document.querySelectorAll("pre");
    codeBlocks.forEach((block) => {
      if (!block.querySelector(".custom-rect")) {
        const copy_div = document.createElement("div");
        const copy_img = document.createElement("img");
        copy_img.src = Copy;
        copy_div.className = "custom-rect position-copy";
        copy_div.addEventListener("click", () => copyCode(block, copy_div));
        copy_div.appendChild(copy_img);
        block.appendChild(copy_div);
      }
    });
  };
