import React from 'react'
import { useTheme } from '../../context/ThemeProvider';
import ScrollToBottomStore from '../../store/ScrollToBottomStore';
import { FaArrowDown } from 'react-icons/fa';

const ScrollToBottomButton = () => {
    const showScrollButton = ScrollToBottomStore(
      (state) => state.showScrollButton
    );
    const { theme } = useTheme();
    const scrollToBottom = (smooth = true) => {
      const scrollRef = document.querySelector("#chat-messages");
      if (scrollRef) {
        scrollRef.scrollTo({
          top: scrollRef.scrollHeight,
          behavior: smooth ? "smooth" : "instant",
        });
      }
    };
    if (showScrollButton)
      return (
        <button
          onClick={() => scrollToBottom(true)}
          className={`absolute -top-12 left-1/2 transform -translate-x-1/2 translate-y-1/2 
               border-[0.5px] opacity-100 h-[26px] w-[26px] flex justify-center items-center rounded-full shadow-md transition z-50 ${
                 theme === "dark"
                   ? "bg-[#30303D] border-[#6D808D]"
                   : "bg-white border-[#D4D6D7]"
               }`}
        >
          <FaArrowDown
            size={12}
            className={`${theme === "dark" ? "text-white" : "text-[#0d3148]"}`}
          />
        </button>
      );
  };

export default ScrollToBottomButton