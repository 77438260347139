import React, { createContext, useState, useEffect, useContext } from 'react';

const ThemeContext = createContext();

const EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour in milliseconds

export const ThemeProvider = ({ children }) => {
  const getPreferredTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    const themeTimestamp = localStorage.getItem('theme_timestamp');

    // Check if theme has expired
    if (savedTheme && themeTimestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(themeTimestamp, 10) > EXPIRATION_TIME) {
        localStorage.removeItem('theme');
        localStorage.removeItem('theme_timestamp');
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      }
      return savedTheme;
    }

    // Otherwise, fall back to the system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  };

  const [theme, setTheme] = useState(getPreferredTheme);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', theme === 'dark');
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === 'dark' ? 'light' : 'dark';
      localStorage.setItem('theme', newTheme);
      localStorage.setItem('theme_timestamp', new Date().getTime().toString());
      return newTheme;
    });
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
