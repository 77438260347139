import { apiUrl } from "../constants/const";

// api for for generate api for summarise title
export async function summariseTitleApi({
  prompt,
  promptId,
  orgId,
  chat_id,
  user,
}) {
  const body = JSON.stringify({
    prompt: prompt,
    prompt_id: promptId,
    org_id: orgId,
    chat_id: chat_id,
    user_id: user?.uid,
  });

  const response = await fetch(apiUrl + "/summarise_title", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });

  if (!response.ok) {
    throw new Error("Failed to summarise title.");
  }

  return response.json();
}

// api call for edited prompt summarise title
export async function editPromptSummariseTitleApi({
  newPrompt,
  prevPromptID,
  orgId,
  chat_id,
  user,
}) {
  const body = JSON.stringify({
    prompt: newPrompt,
    prompt_id: prevPromptID,
    org_id: orgId,
    chat_id: chat_id,
    user_id: user?.uid,
  });

  const response = await fetch(apiUrl + "/summarise_title", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });

  if (!response.ok) {
    throw new Error("Failed to summarise title.");
  }

  return response.json();
}
