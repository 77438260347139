import React, { useState } from "react";
import { useTheme } from "../../context/ThemeProvider";
import { BlackCopy, Copy } from "../../assets";
import DOMPurify from "dompurify";
import { FaCheck } from "react-icons/fa6";
import CustomTooltip from "../ToolTip/ToolTip";
import { REGEX_BOLD, REGEX_CODE_BLOCK, REGEX_ESCAPE_GT, REGEX_ESCAPE_LT, REGEX_ITALIC, REGEX_NEWLINE } from "../../constants/regexValues";

// Function to preserve markdown formatting
const preserveMarkdownFormatting = (text) => {
  return text;
};

// Function to convert Markdown-style text to HTML
const convertMarkdownToHTML = (text) => {
  let html = text
    // Preserve code blocks first (to avoid other transformations inside them)
    .replace(REGEX_CODE_BLOCK, (match, code) => {
      // Create proper code block with pre/code tags
      return `<pre style="background-color: #f4f4f4; padding: 10px; border-radius: 5px; margin: 10px 0;"><code>${code
        .replace(REGEX_ESCAPE_LT, "&lt;")
        .replace(REGEX_ESCAPE_GT, "&gt;")
        .replace(REGEX_NEWLINE, "<br>")}</code></pre>`;
    })
    // Inline code
    .replace(/`([^`]+)`/g, (match, code) => {
      return `<code style="background-color: #f4f4f4; padding: 2px 4px; border-radius: 3px; font-family: monospace;">${code
        .replace(REGEX_ESCAPE_LT, "&lt;")
        .replace(REGEX_ESCAPE_GT, "&gt;")}</code>`;
    })
    .replace(REGEX_BOLD, "<strong>$1</strong>")
    .replace(REGEX_ITALIC, "<em>$1</em>")
    .replace(REGEX_NEWLINE, "<br>");

  return html;
};

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);
  const { theme } = useTheme();

  const handleCopy = async () => {
    try {
      // Convert Markdown-style text to rich HTML
      const formattedHTML = `
        <html>
          <head><meta charset="utf-8"></head>
          <body style="font-family: Arial, sans-serif; line-height: 1.5; white-space: pre-wrap;">
            ${DOMPurify.sanitize(convertMarkdownToHTML(text))}
          </body>
        </html>
      `;

      // Use exactly the original text for plain text version to preserve all markdown
      const plainText = preserveMarkdownFormatting(text);

      if (navigator.clipboard?.write) {
        const clipboardItem = new ClipboardItem({
          "text/html": new Blob([formattedHTML], { type: "text/html" }),
          "text/plain": new Blob([plainText], { type: "text/plain" }),
        });
        await navigator.clipboard.write([clipboardItem]);
      } else {
        // Fallback for older browsers
        const textarea = document.createElement("textarea");
        textarea.value = plainText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }

      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Copy failed:", err);
    }
  };

  return (
      <CustomTooltip text={copied ? "Copied to Clipboard" : "Copy Response"} position="top">
        <button onClick={handleCopy} size="small" disabled={copied}>
          <div
            className={`p-2 rounded-full transition-colors ${
              copied
                ? "bg-[#FFEBE6] dark:bg-[#3A4D67]"
                : "hover:bg-gray-100 dark:hover:bg-[#A8B2D1]"
            }`}
          >
            {copied ? (
              <FaCheck className="w-4 h-4 text-[#FF5B29] dark:text-[#37E8C5] animate-pulse" />
            ) : theme === "dark" ? (
              <Copy className="w-4 h-4 hover:scale-110 transition-transform" />
            ) : (
              <BlackCopy className="h-4 w-4 hover:scale-110 transition-transform" />
            )}
          </div>
        </button>
      </CustomTooltip>
  );
};

export default CopyButton;
