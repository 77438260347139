import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import addChat from "./addChat";
import UserAuthStore from "../../store/userStore";
import PromptStore from "../../store/promptStore";
import ChatStore from "../../store/chatStore";
import EnvStore from "../../store/secretStore";

function convertData(doc) {
  const data = doc.data();
  return {
    id: doc.id,
    ...data,
    timestamp: data.timestamp?.toDate().toString(),
    updated_at: data.updated_at?.toDate().toString(),
  };
}

export async function getChat(first, RemoveUpload) {
  const { setPromptDisable } = PromptStore.getState();
  const { setSideChat } = ChatStore.getState();
  const { user, orgId } = UserAuthStore.getState();

  setPromptDisable(true);

  try {
    const chatsRef = collection(
      EnvStore.getState().db,
      "organisation",
      orgId,
      "users",
      user?.uid,
      "chats"
    );

    const chatsQuery = query(
      chatsRef,
      where("pinned", "==", false),
      orderBy("updated_at", "desc")
    );

    const pinnedChatsQuery = query(
      chatsRef,
      where("pinned", "==", true),
      orderBy("pinned_date", "desc")
    );

    const querySnapshot = await getDocs(chatsQuery);
    const pinQuerySnapshot = await getDocs(pinnedChatsQuery);
    const chats = [
      ...pinQuerySnapshot.docs.map((doc) => convertData(doc)),
      ...querySnapshot.docs.map((doc) => convertData(doc)),
    ];

    first = false;
    if (chats?.length > 0) {
    } else {
      await addChat();
    }
    setSideChat(chats);
    setPromptDisable(false);
  } catch (error) {
    console.log("in get chat", error);
  }
}
