import { signOut } from 'firebase/auth';
import UserAuthStore from '../store/userStore';
import EnvStore from '../store/secretStore';

export const logout = async () => {
  const { setUser } = UserAuthStore.getState();

  try {
    try {
      await signOut(EnvStore.getState().auth);
    } catch (error) {
      console.error("User already signed out");
    }

    // Clear local storage
    localStorage.clear();

    setUser(null);
    window.location.href = "/";
  } catch (error) {
    console.error("Logout failed:", error);
  }
};
