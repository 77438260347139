import React from "react";

const Loader = () => {
  const loaderStyle = {
    border: "4px solid transparent",
    borderTopColor: "#3b82f6",
    borderRadius: "50%",
    width: "3rem",
    height: "3rem",
    animation: "spin 1s linear infinite",
  };

  return (
    <div style={loaderStyle}>
      <style>
        {`
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          `}
      </style>
    </div>
  );
};

export default Loader;
