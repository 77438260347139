import { useEffect } from "react";
import { BlackNotebook, BlackSetting, Notebook, Setting } from "../../assets";

export const SidebarToggler1 = ({ showSidebar, toggleSidebar, theme }) => {
    return (
      !showSidebar && (
        <div
          className={`fixed top-2 left-4 z-[10001] h-10 w-10 cursor-pointer flex justify-center items-center rounded-full ${
            theme === "dark"
              ? "bg-[#424453] border-[#707070] border-[0.5px]"
              : "bg-[#FFEBE6] border-[#CCCCCC] border-[0.5px]"
          }`}
          onClick={toggleSidebar}
        >
          {theme === "dark" ? <Notebook /> : <BlackNotebook />}
        </div>
      )
    );
  };
  
export const SideBarHeader = ({
    theme,
    toggleSidebar,
    toggleTheme,
    isSettingsDropdownOpen,
    setIsSettingsDropdownOpen,
    isSidebarOpen,
    setIsSidebarOpen,
  }) => {
    const closeDropdown = (e) => {
      if (!e.target.closest(".settings-dropdown")) {
        setIsSettingsDropdownOpen(false);
      }
    };
  
    const closeSidebar = (e) => {
      if (!e.target.closest(".sidebar") && window.innerWidth < 1024) {
        setIsSidebarOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("click", closeDropdown);
      document.addEventListener("click", closeSidebar);
      return () => {
        document.removeEventListener("click", closeDropdown);
        document.removeEventListener("click", closeSidebar);
      };
    }, []);
  
    const handleThemeToggle = (e) => {
      e.stopPropagation();
      toggleTheme();
    };
  
    return (
      <>
        {isSidebarOpen && window.innerWidth < 1024 && (
          <div
            className="fixed inset-0 bg-[#22222D] bg-opacity-[40%] z-40"
            onClick={() => setIsSidebarOpen(false)}
          />
        )}
        <div className="flex items-center justify-between px-3 mb-2 mt-4">
          <div
            onClick={toggleSidebar}
            className={`h-10 w-10 cursor-pointer flex justify-center items-center rounded-full ${
              theme === "dark"
                ? "bg-[#424453] border-[#707070] border-[0.5px]"
                : "bg-[#FFEBE6] border-[#CCCCCC] border-[0.5px]"
            }`}
          >
            {theme === "dark" ? (
              <Notebook onClick={toggleSidebar} />
            ) : (
              <BlackNotebook onClick={toggleSidebar} />
            )}
          </div>
          <div
            className={`relative h-10 w-10 cursor-pointer flex justify-center items-center rounded-full ${
              theme === "dark"
                ? "bg-[#424453] border-[#707070] border-[0.5px]"
                : "bg-[#FFEBE6] border-[#CCCCCC] border-[0.5px]"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setIsSettingsDropdownOpen((prev) => !prev);
            }}
          >
            <div
              className={`transition-transform duration-300 ${
                isSettingsDropdownOpen ? "rotate-0" : "rotate-180"
              }`}
            >
              {theme === "dark" ? <Setting /> : <BlackSetting />}
            </div>
            {isSettingsDropdownOpen && (
              <div
                className={`absolute right-0 xm:left-0 top-12 w-40 border rounded-md shadow-lg z-50 settings-dropdown ${
                  theme === "dark"
                    ? "bg-[#424453] border-[#66666666]"
                    : "bg-white border-[#66666640]"
                }`}
              >
                <div className="py-2 px-3 border-b dark:border-[#66666666] border-[#CCCCCC]">
                  <h3
                    className={`text-[11px] font-semibold opacity-100 ${
                      theme === "dark" ? "text-[#9FABCA]" : "text-[#6D808D]"
                    }`}
                  >
                    Settings
                  </h3>
                </div>
                <div className="py-2 px-3 flex items-center justify-between">
                  <span
                    className={`text-[13px] ${
                      theme === "dark" ? "text-white" : "text-[#0D3148]"
                    }`}
                  >
                    Dark Theme
                  </span>
  
                  {/* Toggle Button */}
                  <div
                    onClick={handleThemeToggle}
                    className={`relative flex items-center w-11 h-6 rounded-full cursor-pointer transition-colors duration-300 ease-out ${
                      theme === "dark" ? "bg-[#37E8C5]" : "bg-[#FF5B29]"
                    }`}
                  >
                    {/* Toggle Circle */}
                    <span
                      className={`absolute top-[2px] left-[2px] w-5 h-5 rounded-full bg-white shadow-md transition-transform duration-300 ease-out ${
                        theme === "dark" ? "translate-x-5" : "translate-x-0"
                      }`}
                    ></span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };