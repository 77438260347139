import { doc, setDoc, updateDoc, arrayUnion, serverTimestamp } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import EnvStore from "../../store/secretStore";


async function addMessages(orgId, chatId, prompt, fullMessage, userId, promptId = null, source = "None", files = [], modelId) {

  if (prompt && fullMessage) {
    try {
      const answerId = uuidv4();

      if (promptId) {
        const promptRef = doc(EnvStore.getState().db, "organisation", orgId, "users", userId, "chats", chatId, "messages", promptId);
        await updateDoc(promptRef, {
          answers: arrayUnion(answerId),
        });
      } else {
        promptId = uuidv4();
        const promptRef = doc(EnvStore.getState().db, "organisation", orgId, "users", userId, "chats", chatId, "messages", promptId);
        await setDoc(promptRef, {
          answers: [answerId],
          role: "user",
          content: prompt,
          source: files,
          timestamp: serverTimestamp(),
        });
      }

      const answerRef = doc(EnvStore.getState().db, "organisation", orgId, "users", userId, "chats", chatId, "messages", answerId);
      await setDoc(answerRef, {
        role: "model",
        active: true,
        content: fullMessage,
        model_id: modelId,
        source: files,
        timestamp: serverTimestamp(),
      });
      return promptId;
    } catch (error) {
      console.error("Error adding messages: ", error);
    }
  } else {
    console.log("No prompt or answer provided.");
  }
}

export default addMessages;
