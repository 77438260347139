import { useEffect, useRef, useState } from "react";
import ChatMessages from "./Messages/messages";
import Header from "./Contents/Header";
import { useNavigate, useParams } from "react-router-dom";
import UserAuthStore from "../../store/userStore";
import { loadFirstChatWithMessageCount } from "../../utils/firestoreFunctions/loadChatMessages";
import ChatStore from "../../store/chatStore";
import "../../assets/css/styles.css";
import { v4 as uuidv4 } from "uuid";
import { FileStore } from "../../store/fileUploadStore";
import UIStore from "../../store/uiStore";
import { doc, getDoc } from "firebase/firestore";
import EnvStore from "../../store/secretStore";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import { handleFileUpload } from "../../utils/fileUploadHandler";

const MainContent = () => {
  const chat_id = useParams().id;
  const fileInputRef = useRef(null);
  const { user, orgId } = UserAuthStore();
  const navigate = useNavigate();
  const { setActiveChat, setTempChatId } = ChatStore();
  const { setExpanded } = UIStore();
  const files = FileStore((state) => state.files);
  const setFiles = FileStore((state) => state.setFiles);
  const [isNewDrag, setIsNewDrag] = useState(false);
  const { handleDrop } = useDragAndDrop();

  const handleDragEnter = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    setIsNewDrag(true);
    console.log("Drag Enter in Main Content");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    setIsNewDrag(true);
    console.log("Drag Over in Main Content");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    setIsNewDrag(false);
    console.log("Drag Leave in Main Content");
  };
  const handleOnChange = (e) => {
    handleFileUpload(e, chat_id, fileInputRef, setFiles, files, user, orgId);
  };

  const handleOnDrop = (e) => {
    setIsNewDrag(false);
    handleDrop(
      e,
      fileInputRef,
      handleFileUpload,
      chat_id,
      setFiles,
      files,
      user,
      orgId
    );
  };

  useEffect(() => {
    const shouldCreateNewChat = async () => {
      if (chat_id === undefined && user?.uid) {
        const lastChat = await loadFirstChatWithMessageCount();
        if (lastChat === null || lastChat?.messageCount) {
          const ch_uid = uuidv4();
          setTempChatId(ch_uid); // Store locally
          setFiles([]);
          setExpanded(false);
          setActiveChat(ch_uid);
          navigate(`/chat/${ch_uid}`);
        } else {
          setActiveChat(lastChat.chatId);
          navigate(`/chat/${lastChat.chatId}`);
        }
      } else {
        if (user?.uid) {
          const userDocRef = await getDoc(
            doc(
              EnvStore.getState().db,
              "organisation",
              orgId,
              "users",
              user?.uid,
              "chats",
              chat_id
            )
          );

          if (!userDocRef.exists()) {
            setTempChatId(chat_id); // Store locally
            setFiles([]);
            setExpanded(false);
            setActiveChat(chat_id);
            navigate(`/chat/${chat_id}`);
          }
        }
      }
    };
    shouldCreateNewChat();
  }, [user, chat_id]);

  return (
    <div
      className={`flex flex-col flex-grow min-w-0 relative hide-scrollbar`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleOnDrop}
    >
      <Header />
      {chat_id ? (
        <ChatMessages
          chat_id={chat_id}
          setIsNewDrag={setIsNewDrag}
          isNewDrag={isNewDrag}
        />
      ) : (
        <></>
      )}
      <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          onChange={handleOnChange}
          accept=".pdf,.csv,.docx,.txt,.xlsx,.doc"
        />
    </div>
  );
};

export default MainContent;
