import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import UIStore from "../../store/uiStore";
import ChatStore from "../../store/chatStore";
import UserAuthStore from "../../store/userStore";
import EnvStore from "../../store/secretStore";
import FileUploadStore from "../../store/fileUploadStore";

const addChat = async () => {
  try {
    const { user, email, orgId } = UserAuthStore.getState();

    const {
      setActiveChat,
      setAddChatDisabled,
      setSideChat,
      sideChat,
      tempChatId
    } = ChatStore.getState();
    const { setInputFileName, setFileSize, setFileUrl, setShowUpload } =
      FileUploadStore.getState();

    const { modeRef } = UIStore.getState();

    setAddChatDisabled(true);
    const RemoveUpload = () => {
      setInputFileName([]);
      setFileSize([]);
      setFileUrl([]);
      setShowUpload(false);
    };

    RemoveUpload();

    const data = {
      uid: tempChatId,
      user_id: user?.uid,
      email: email,
      title: "New Chat",
      file_url: [],
      file_name: [],
      file_size: [],
      file_path: [],
      space: null,
      pinned: false,
      pinned_date: null,
      mode: modeRef,
      timestamp: serverTimestamp(),
      updated_at: serverTimestamp(),
    };
    const userDocRef = doc(
      EnvStore.getState().db,
      "organisation",
      orgId,
      "users",
      user?.uid,
      "chats",
      tempChatId
    );

    await setDoc(userDocRef, data);
    setActiveChat(tempChatId);

    const pinnedChats = sideChat.filter(chat => chat.pinned); // Assuming pinned chats have `isPinned: true`
    const nonPinnedChats = sideChat.filter(chat => !chat.pinned);

    const newChats = [...pinnedChats, data, ...nonPinnedChats];


    setSideChat(newChats);
    // setPrompt("");
    // getChat(modeRef, email);
    return tempChatId;
  } catch (error) {
    console.error("Error adding chat in Firestore:", error);
  }
};

export default addChat;
