import { useNavigate } from "react-router-dom";
import ChatStore from "../../store/chatStore";
import UIStore from "../../store/uiStore";
import { FileStore } from "../../store/fileUploadStore";
import { v4 as uuidv4 } from "uuid";
import { BlackPlus, Plus } from "../../assets";
import useGenerateApiSignal from "../../store/useGenerateApiSignal";

export const NewChatButton = ({ theme }) => {
    const { addChatDisabled, setTempChatId } = ChatStore();
    const { setExpanded } = UIStore();
    const navigate = useNavigate();
    const setFiles = FileStore((state) => state.setFiles);

    const handleAddChat = async () => {
      useGenerateApiSignal.getState().signal?.abort();
      if (addChatDisabled) return;
      const chat_id = uuidv4(); // Temporary ID (UUID)
      setTempChatId(chat_id); // Store locally
      setFiles([]);
      setExpanded(false);
      navigate(`/chat/${chat_id}`);
    };
    return (
      <div
        onClick={handleAddChat}
        className={`p-2 h-[38px] rounded-md mt-3 flex items-center ${
          theme === "dark"
            ? "bg-[#444858] text-white hover:opacity-[60%] opacity-[100%]"
            : "bg-[#FFEAE6] hover:opacity-[60%] opacity-[100%] text-black"
        } ${addChatDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
      >
        {theme === "dark" ? (
          <Plus className="mr-1 h-4" />
        ) : (
          <BlackPlus className="mr-1 h-4" />
        )}
        <span className="ml-2 text-sm font-light">New Chat</span>
      </div>
    );
  };