import axios from "axios";
import { collection, doc, query, where, getDocs, setDoc , serverTimestamp } from 'firebase/firestore';
import EnvStore from "../../store/secretStore";
import { apiUrl } from '../../constants/const';

export const updateUserDocumentId = async (targetEmail, newDocId, orgId , userData) => {
  try {
    const usersRef = collection(
      doc(collection(EnvStore.getState().db, "organisation"), orgId),
      "users"
    );

    // Query to find the user document by email
    const q = query(usersRef, where("email", "==", targetEmail));
    const querySnapshot = await getDocs(q);

    const api = apiUrl + "/migrate";

    if (!querySnapshot.empty) {
        const oldDoc = querySnapshot.docs[0];
        const newDocRef = doc(usersRef, newDocId);

        const requestBody = {
          source: `organisation/synapses/users/${oldDoc.id}`,
          destination: `organisation/synapses/users/${newDocId}`,
      };
      
      try {
          await axios.post(api, requestBody);
          return true
      } catch (error) {
          console.error("API Error:",error);
          return false
      }
    } else {
        const usersRef = collection(EnvStore.getState().db, 'organisation', orgId, 'users');
        const userDocRef = doc(usersRef, newDocId);
      
        await setDoc(userDocRef, {
          user_id: newDocId,
          email: userData.email,
          name: userData.displayName || '',
          picture: userData.photoURL || '',
          access_token: userData.accessToken,
          refresh_token: userData.refreshToken,
          org_mode: false,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        });
      console.log("No user document found with the specified email.");
      return true;
    }
  } catch (error) {
    console.error("Error updating user document ID:", error);
    return false
  }
};
