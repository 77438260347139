import useGenerateApiSignal from "../store/useGenerateApiSignal";
import { getCacheAndFileData } from "../utils/firestoreFunctions/getCacheandFileData";
import { getRecachingValue } from "../utils/firestoreFunctions/getRecaching";
import { editPromptSummariseTitleApi } from "./SummariseTitle";

export const handleEditSend = async ({
  chat_id,
  expanded,
  selectedStyle,
  setMessages,
  messages,
  setRegenerateIndex,
  setIsLatestMessageLoading,
  updateChatTitle,
  user,
  orgId,
  files,
  modeRef,
  apiUrl,
  defaultModel,
  index,
  newPrompt,
  prevPrompt,
  prevPromptID,
  setGenerateStatus,
}) => {
  try {
    // Show loader
    setRegenerateIndex(index);
    setGenerateStatus(true);
    setIsLatestMessageLoading(true);

    const reader = await generateApi({
      apiUrl,
      modeRef,
      newPrompt,
      chat_id,
      files,
      orgId,
      user,
      defaultModel,
      expanded,
      selectedStyle,
      messages: messages[index].id,
      prevPrompt,
      prevPromptID,
    });
    const decoder = new TextDecoder("utf-8");
    let answerCont = "";
    for (let done = false; !done;) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;
      if (value) {
        const chunk = decoder.decode(value, { stream: true });
        answerCont += chunk;

        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const messageToUpdate = updatedMessages[index];

          if (!messageToUpdate.answers) {
            messageToUpdate.answers = [];
          }
          messageToUpdate.answers[0] = { content: answerCont }

          return updatedMessages;
        });

      }
    }
    if (index === 0) {
      const generatedTitle = await editPromptSummariseTitleApi({ newPrompt, prevPromptID, orgId, chat_id, user });
      updateChatTitle(chat_id, generatedTitle["summary_title"]);
    }
    setRegenerateIndex(null);
    setGenerateStatus(false);
    setIsLatestMessageLoading(false);
  } catch (error) {
    if (error.name === "AbortError") {
      setRegenerateIndex(null);
      setGenerateStatus(false);
      setIsLatestMessageLoading(false);
    } else {
      console.error("Error fetching messages:", error);
    }
  }
};

export async function generateApi({
  apiUrl,
  modeRef,
  chat_id,
  files,
  orgId,
  user,
  defaultModel,
  expanded,
  selectedStyle,
  newPrompt,
  prevPrompt,
  prevPromptID
}) {
  const dta = await getCacheAndFileData(chat_id);

  const recachingValue = await getRecachingValue(chat_id);

  const cacheId = dta?.cache_id ?? null;
  const fileData = dta?.file_data ?? "";

  const body = JSON.stringify({
    type: modeRef,
    prompt: prevPrompt,
    chat_uid: chat_id,
    file_url: files?.map((file) => file.downloadURL) || [],
    org_id: orgId,
    uid: user?.uid,
    regenerate: false,
    style: selectedStyle,
    model_id: defaultModel,
    recaching: recachingValue || false,
    google_search: expanded,
    cache_id: cacheId,
    file_data: fileData,
    prompt_id: prevPromptID,
    new_prompt: newPrompt,
  });

  //code for api abort/pause
  useGenerateApiSignal.getState().signal?.abort();
  const newAbortSignal = new AbortController();

  const response = await fetch(apiUrl + "/generate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
    signal: newAbortSignal.signal,
  });

  useGenerateApiSignal.getState().setSignal(newAbortSignal);

  if (!response.body) {
    throw new Error("Readable stream not supported in response.");
  }

  return response.body.getReader();
}