import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import UserAuthStore from "../../store/userStore";
import ChatStore from "../../store/chatStore";
import EnvStore from "../../store/secretStore";

export const updateChatTitle = async (uid, title) => {
  const {
    updateChatTitle: update,
    setEditChatId,
    setEditTitle,
  } = ChatStore.getState();
  const { user, orgId } = UserAuthStore.getState();

  try {
    update(uid, title);

    // Reset editing states
    setEditChatId("");
    setEditTitle("");

    // Reference Firestore document
    const chatDocRef = doc(
      EnvStore.getState().db,
      "organisation",
      orgId,
      "users",
      user?.uid,
      "chats",
      uid
    );

    // Update Firestore document
    await updateDoc(chatDocRef, {
      title: title,
      updated_at: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error updating chat title in Firestore:", error);
  }
};

export const pinChatAPI = async (uid, value = true) => {
  const { user, orgId } = UserAuthStore.getState();

  try {
    // update(uid, title);

    // Reset editing states
    // setEditChatId("");
    // setEditTitle("");

    // Reference Firestore document
    const chatDocRef = doc(
      EnvStore.getState().db,
      "organisation",
      orgId,
      "users",
      user?.uid,
      "chats",
      uid
    );

    // Update Firestore document
    await updateDoc(chatDocRef, {
      pinned: value,
      pinned_date: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error updating chat title in Firestore:", error);
  }
};
