import { Navigate, Outlet } from "react-router-dom";
import UserAuthStore from "../store/userStore";

const ProtectedRoute = () => {
  const user = UserAuthStore((state) => state.user); // Using Zustand hook for reactivity

  if (user === undefined) {
    // Show a loading indicator while checking auth status
    return <div>Loading...</div>;
  }

  return user ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
