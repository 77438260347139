import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import UserAuthStore from "../../../store/userStore";
import { apiUrl } from "../../../constants/const";
import UIStore from "../../../store/uiStore";
import OneMessage from "./SingleMessage";
import "../../../assets/css/styles.css";
import { useTheme } from "../../../context/ThemeProvider";
import ChatStore from "../../../store/chatStore";
import getMessages from "../../../utils/firestoreFunctions/getMessages";
import { FileStore } from "../../../store/fileUploadStore";
import { invokeCloudRun } from "../../../api/invoke";
import { handleSend } from "../../../api/generate";
import regenerate from "../../../api/regenerate";
import { getDefaultModel } from "../../../api/getDefaultModel";
import { handleEditSend } from "../../../api/EditedPrompt";
import DashSection from "../Contents/DashSection";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";
import { handleFileUpload } from "../../../utils/fileUploadHandler";
import Prompt from "../../Prompt/Prompt";
import ScrollToBottomStore from "../../../store/ScrollToBottomStore";
import { serverTimestamp } from "firebase/firestore";

const ChatMessages = ({ chat_id, isNewDrag, setIsNewDrag }) => {
  const messageContainerRef = useRef(null);
  const { theme } = useTheme();
  const [messages, setMessages] = useState([]);
  const [isMessagesRendered, setMessagesRendered] = useState(false);
  const { user, orgId } = UserAuthStore();
  const { modeRef } = UIStore.getState();
  const { expanded, setExpanded } = UIStore();
  const { updateChatTitle, setAddChatDisabled, setIsLatestMessageLoading } =
    ChatStore();
  const { files, setFiles } = FileStore();
  const fileInputRef = useRef(null);
  const [defaultModel, setDefaultModel] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null); // to add the response style
  const [regenerateIndex, setRegenerateIndex] = useState(null); //this state is for the current index of the message that is being regenerated
  const setShowScrollButton = ScrollToBottomStore(
    (state) => state.setShowScrollButton
  );
  const [editedIndex, setEditedIndex] = useState({});
  const [generateStatus, setGenerateStatus] = useState(null);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const { handleDrop } = useDragAndDrop();

  const scrollToBottom = (smooth = true) => {
    const scrollRef = document.querySelector("#chat-messages");
    if (scrollRef) {
      scrollRef.scrollTo({
        top: scrollRef.scrollHeight,
        behavior: smooth ? "smooth" : "instant",
      });
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      if (user?.uid) {
        const out = await getMessages(chat_id);
        setMessages(out);
        setHasLoadedOnce(true);
        scrollToBottom();
      }
    };
    invokeCloudRun();
    const getDefaultModelValue = async () => {
      const model = await getDefaultModel();
      setDefaultModel(model.model);
    };
    getDefaultModelValue();
    fetchMessages();

    return () => {
      setIsLatestMessageLoading(false);
      setShowScrollButton(false);
    };
  }, [chat_id, user?.uid, orgId]);

  useLayoutEffect(() => {
    setTimeout(() => {
      scrollToBottom(false);
      setMessagesRendered(true);
    }, 10);
  }, [messages.length]);

  // Detect scrolling behavior
  useEffect(() => {
    const handleScroll = () => {
      if (messageContainerRef.current) {
        const isAtBottom =
          messageContainerRef.current.scrollHeight -
            messageContainerRef.current.scrollTop <=
          messageContainerRef.current.clientHeight + 20;

        setShowScrollButton(!isAtBottom);
      }
    };

    const scrollContainer = messageContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      // Run the scroll check once after mounting
      handleScroll();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages.length]);

  const editFunctionality = (index, newPrompt) => {
    const newMessages = [...messages];
    const prevPromptID = newMessages[index].id;

    const prevPrompt = newMessages[index].content;

    newMessages[index].content = newPrompt;
    newMessages[index].answers = [
      {
        content: "",
        timestamp: serverTimestamp(),
        source: "None",
        active: true,
        role: "model",
      },
    ];
    setMessages(newMessages);
    handleEditSend({
      chat_id,
      expanded,
      setExpanded,
      setSelectedStyle,
      selectedStyle,
      setMessages,
      messages,
      setRegenerateIndex,
      setIsLatestMessageLoading,
      setAddChatDisabled,
      updateChatTitle,
      user,
      orgId,
      files,
      modeRef,
      apiUrl,
      defaultModel,
      index,
      newPrompt,
      prevPrompt,
      prevPromptID,
      generateStatus,
      setGenerateStatus,
    });
  };

  if (!hasLoadedOnce) {
    return null;
  }

  const isNewChat = hasLoadedOnce && messages.length === 0;
  return (
    <>
      {isNewChat ? (
        <div
          className="flex flex-col flex-grow text-center xm:mt-14 xs:mt-14 xxs:mt-14 px-6 max-w-5xl w-full mx-auto"
          onDrop={(e) =>
            handleDrop(
              e,
              fileInputRef,
              handleFileUpload,
              chat_id,
              setFiles,
              files,
              user,
              orgId
            )
          }
        >
          <DashSection
            chat_id={chat_id}
            fileInputRef={fileInputRef}
            setIsNewDrag={setIsNewDrag}
            isNewDrag={isNewDrag}
          />{" "}
        </div>
      ) : (
        <div
          id="chat-messages"
          ref={messageContainerRef}
          className={`side-chat-scroll font-sans flex-grow max-w-full ${
            isMessagesRendered ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="max-w-5xl px-6 mx-auto h-full">
            {messages?.map((chat, index) => (
              <OneMessage
                key={chat.id}
                index={index}
                chat={chat}
                user={user}
                isLatest={index === messages.length - 1}
                theme={theme}
                regenerateIndex={regenerateIndex}
                setRegenerateIndex={setRegenerateIndex}
                editedContent={editedIndex}
                setEditedContent={setEditedIndex}
                editFunctionality={editFunctionality}
                generateStatus={generateStatus}
                setGenerateStatus={setGenerateStatus}
                regenFunc={(id, modelSelected, globalSearchDropdown) => {
                  const m = modelSelected ? modelSelected : defaultModel;
                  return regenerate({
                    id,
                    messages,
                    setMessages,
                    modeRef,
                    chat_id,
                    orgId,
                    user,
                    updateChatTitle,
                    files,
                    modelSelected: m,
                    setIsLatestMessageLoading,
                    globalSearchDropdown,
                    setRegenerateIndex,
                    setGenerateStatus,
                  });
                }}
              />
            ))}
          </div>
        </div>
      )}

      <Prompt
        messageLength={messages.length}
        chat_id={chat_id}
        expanded={expanded}
        setExpanded={setExpanded}
        setSelectedStyle={setSelectedStyle}
        selectedStyle={selectedStyle}
        generateStatus={generateStatus}
        setGenerateStatus={setGenerateStatus}
        setIsNewDrag={setIsNewDrag}
        isNewDrag={isNewDrag}
        send={() =>
          handleSend({
            chat_id,
            expanded,
            setExpanded,
            setSelectedStyle,
            selectedStyle,
            setMessages,
            messages,
            setRegenerateIndex,
            setIsLatestMessageLoading,
            setAddChatDisabled,
            updateChatTitle,
            user,
            orgId,
            files,
            modeRef,
            apiUrl,
            defaultModel,
            generateStatus,
            setGenerateStatus,
          })
        }
      />
    </>
  );
};

export default ChatMessages;
