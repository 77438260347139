import { doc, getDoc } from "firebase/firestore";
import UserAuthStore from "../../store/userStore";
import EnvStore from "../../store/secretStore";

export const getCacheAndFileData = async (uid) => {
    const { user, orgId } = UserAuthStore.getState();

    try {
        const chatDocRef = doc(
            EnvStore.getState().db,
            "organisation",
            orgId,
            "users",
            user?.uid,
            "chats",
            uid
        );

        // Fetch Firestore document
        const chatDocSnap = await getDoc(chatDocRef);

        if (chatDocSnap.exists()) {
            
            // Document exists, return its data
            return chatDocSnap.data();
        } else {
            // Document does not exist, return null
            console.warn(`Document with ID ${uid} does not exist.`);
            return null;
        }
    } catch (error) {
        console.error("Error fetching data from Firestore:", error);
        return null; // Return null in case of an error
    }
};
