import { doc, deleteDoc } from "firebase/firestore";
import ChatStore from "../../store/chatStore";
import UserAuthStore from "../../store/userStore";
import EnvStore from "../../store/secretStore";

export const deleteChat = async (uid, sideChat) => {
  const { activeChat, deleteChatTitle } = ChatStore.getState();
  const { user, orgId } = UserAuthStore.getState();

  try {
    if (activeChat !== uid) {
      deleteChatTitle(uid);

      // Delete the chat from Firestore
      await deleteDoc(
        doc(EnvStore.getState().db, "organisation", orgId, "users", user?.uid, "chats", uid)
      );

      // Update chat disable state after deletion
      const updatedSideChat = sideChat.filter(chat => chat.uid !== uid);

      // if (updatedSideChat.length === 0 || updatedSideChat[0]?.title === "New Chat") {
      //   setAddChatDisabled(false); // Enable "Add New Chat" if no chats or "New Chat" is first
      // } else {
      //   setAddChatDisabled(true);
      // }
    }
  } catch (error) {
    console.error("Error deleting chat:", error);
  }
};
